<template>
    <div class="page" id="blacklist">
        <div class="query-form p_b1">
            <el-input size="small" v-model="name" placeholder="请输入报名人姓名" clearable style="width: 20%"></el-input>
            <el-select class="m_r1 m_l1" size="small" v-model="selectState" placeholder="状态选择" style="width: 15%">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-select class="m_r1" size="small" v-model="selectType" placeholder="证件选择" style="width: 15%">
                <el-option
                        v-for="item in documentType"
                        :key="item.id"
                        :label="item.cardTypeName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-button size="small" @click="query" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            <el-button class="f_r" v-if="hasPermission('activity:blacklist:setUp')" size="small" @click="setUp" type="primary">
                拉黑设置
            </el-button>
        </div>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 280px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="blackName" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cardTypeName" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackCard" label="证件号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackPhone" label="联系电话" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackState" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.blackState == 1 ? '警告' : scope.row.blackState == 2 ? '拉黑' : '已补签' }}
                    </template>
                </el-table-column>
                <el-table-column prop="blackDay" label="禁止天数" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ parseInt(scope.row.blackDay) >= 99999 ? '永久' : scope.row.blackDay}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="300" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                v-if="hasPermission('activity:blacklist:addTo') && scope.row.blackState == 2 && parseInt(scope.row.blackDay) < 99999"
                                type="text"
                                size="mini" @click="block(scope.row,2)">加时长
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:blacklist:addTo') && scope.row.blackState == 2"
                                type="text"
                                size="mini" @click="addTo(scope.row)">释放
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:blacklist:block') && scope.row.blackState != 2"
                                type="text"
                                size="mini" @click="block(scope.row,1)">拉黑
                        </el-button>
                        <el-button
                                v-if="hasPermission('activity:blacklist:details')"
                                type="text"
                                size="mini" @click="details(scope.row)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    :current-page="pageNo"
                    :page-size="pageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    :total="total"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <!--        拉黑-->
        <el-dialog
                title="拉黑设置"
                :visible.sync="dialogVisible"
                width="40%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="ruleFormBalck" :rules="rulesBalck" ref="ruleFormBalck" label-width="100px"
                     class="query-form">
                <el-row :gutter="20">
                    <el-form-item label="禁止天数" required>
                        <el-col :span="8">
                            <el-form-item prop="selectDay">
                                <el-select size="small" @change="blackDaysSelection" v-model="ruleFormBalck.selectDay"
                                           placeholder="请选择">
                                    <el-option
                                            v-for="item in service"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="this.ruleFormBalck.selectDay == ''">
                            <el-form-item prop="day">
                                <el-input show-word-limit clearable size="small" min="0" v-model="ruleFormBalck.day"
                                          oninput="this.value=this.value.replace(/[^\d]/g,'')" onpaste="return false;"
                                          placeholder="天"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" v-if="this.ruleFormBalck.selectDay == ''">
                            <div style="white-space: nowrap;">天</div>
                        </el-col>
                    </el-form-item>
                    <!--<el-form-item label="消息推送" prop="xingshi">
                        <el-input rows="5" size="small" type="textarea" style="width: 85%" maxlength="35"
                                  oninput="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')"
                                  show-word-limit v-model="ruleFormBalck.xingshi"></el-input>
                    </el-form-item>-->
                </el-row>
            </el-form>
            <el-row>
                <el-col :span="5" :offset="4">
                    <el-button size="small" type="primary" @click="lahei('ruleFormBalck')">拉黑</el-button>
                </el-col>
                <el-col :span="5" :offset="8">
                    <el-button @click="resetForm('ruleFormBalck')" size="small">取消</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <!--        消息推送-->
        <el-dialog
                title="消息推送"
                :visible.sync="dialogVisibleMessage"
                width="40%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="ruleFormBalck" :rules="rulesBalck" ref="ruleFormBalck" label-width="100px"
                     class="query-form">
                <el-row :gutter="20">
                    <el-form-item label="内容" prop="sendMessage">
                        <el-input rows="5" size="small" type="textarea" style="width: 85%" maxlength="30"
                                  oninput="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')"
                                  show-word-limit v-model="ruleFormBalck.sendMessage"></el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <el-row>
                <el-col :span="5" :offset="4">
                    <el-button size="small" type="primary" @click="gerenxiaoxi('ruleFormBalck')">发消息</el-button>
                </el-col>
                <el-col :span="5" :offset="8">
                    <el-button @click="resetForm('ruleFormBalck')" size="small">取消</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <!--        详情-->
        <el-dialog
                title="详情数据"
                :visible.sync="detailedData"
                width="60%"
                :close-on-click-modal="false"
                :before-close="handleCloseData">
            <el-row :gutter="20">
                <el-col :span="8" :offset="1">
                    <div>姓名：{{ userNamw }}</div>
                </el-col>
                <el-col :span="10">
                    <div>联系电话：{{ userphone }}</div>
                </el-col>
            </el-row>

            <div class="bg-white top">
                <el-menu :default-active="tabIndex" @select="handleSelectTab" class="el-menu-demo" mode="horizontal">
                    <el-menu-item index="0">未签到记录</el-menu-item>
                    <el-menu-item index="1">拉黑记录</el-menu-item>
                </el-menu>

                <el-table
                        :data="detailedList"
                        v-loading="detailedloading"
                        size="small"
                        height="300px"
                        class="table">
                    <el-table-column
                            label="序号"
                            type="index"
                            width="50">
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 0" prop="activityName" label="报名活动" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 0" prop="startTime" label="场次" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.startTime + '-' + scope.row.endTime}}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 1" prop="createTime" label="拉黑时间" width="200"
                                     show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 1" prop="blackDay" label="禁止天数" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ parseInt(scope.row.blackDay) >= 99999 ? '永久' : scope.row.blackDay}}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 1" prop="createUser" label="拉黑操作人" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 1" prop="createRelease" label="释放操作人" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column v-if="tabIndex == 1" prop="releaseTime" label="释放时间" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
                <el-pagination
                        :current-page="detailedpageNo"
                        :page-size="detailedpageSize"
                        :page-sizes="[10, 20, 50, 100]"
                        :total="detailedtotal"
                        @size-change="detailedSizeChangeHandle"
                        @current-change="detailedCurrentChangeHandle"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <div style="text-align: center">
                <el-button size="small" @click="handleCloseData" type="primary">关闭</el-button>
            </div>
        </el-dialog>


        <!--设置弹窗-->
        <el-dialog
                title="黑名单规则设置"
                :visible.sync="setUpData"
                width="30%"
                :close-on-click-modal="false"
                :before-close="setUpDataHandleCloseData">
            <el-form class="query-form">
                <el-row>
                    <el-col :span="4">
                        <div class="qinmianw">警告</div>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="未签到次数">
                            <el-select size="small" v-model="warningSelect"
                                       placeholder="请选择" @change="checkManagement">
                                <el-option
                                        v-for="item in warningList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <div class="qinmianw">拉黑</div>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="未签到次数">
                            <el-select size="small" v-model="pullBlackSelsct"
                                       placeholder="请选择" @change="checkManagement">
                                <el-option
                                        v-for="item in warningList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row>
                <el-col :span="5" :offset="4">
                    <el-button size="small" type="primary" :disabled="jidongyong" @click="setUpSure">确定</el-button>
                </el-col>
                <el-col :span="5" :offset="8">
                    <el-button @click="setUpCancel" size="small">取消</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "blacklist",
        data() {
            return {
                setUpData: false,
                setUpTabIndex: '0',
                tabIndexOne: '0', //切换活动和讲座
                ruleForm: {},
                name: '',
                selectState: '',//状态
                selectType: '',//证件类型
                options: [
                    {
                        value: '',
                        label: '全部',
                    },
                    {
                        value: 1,
                        label: '警告',
                    },
                    {
                        value: 2,
                        label: '拉黑',
                    },
                ],
                documentType: [],
                loading: false,
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dialogVisible: false,//拉黑弹窗
                ruleFormBalck: {
                    selectDay: 7,
                    day: '',
                    xingshi: '',
                    sendMessage: '',
                },
                rulesBalck: {
                    day: [{
                        required: true, message: '请填写禁止天数', trigger: 'blur'
                    }],
                    xingshi: [{
                        required: true, message: '请填写消息', trigger: 'blur'
                    }],
                    sendMessage: [{
                        required: true, message: '请填写消息', trigger: 'blur'
                    }],
                },
                service: [
                    {
                        value: 180,
                        label: '180天',
                    },
                    {
                        value: 99999,
                        label: '永久禁止',
                    },
                    {
                        value: '',
                        label: '自定义',
                    },
                ],
                //消息推送
                dialogVisibleMessage: false,
                //详情数据
                detailedData: false,
                userNamw: '',
                userphone: '',
                userIDcard: '',
                tabIndex: '0',
                detailedList: [],
                detailedloading: false,
                detailedpageNo: 1,
                detailedpageSize: 10,
                detailedtotal: 0,

                id: '',//id
                blackDay: 7,//拉黑天数
                laheioradd: '',
                warningSelect: 0,
                pullBlackSelsct: 1,
                warningSelect1: 0,
                pullBlackSelsct1: 1,
                laheiGuiz: [],
                warningList: [
                    {
                        value: 0,
                        label: '不启用',
                    },
                    {
                        value: 1,
                        label: '1次',
                    },
                    {
                        value: 2,
                        label: '2次',
                    },
                    {
                        value: 3,
                        label: '3次',
                    },
                    {
                        value: 4,
                        label: '4次',
                    },
                    {
                        value: 5,
                        label: '5次',
                    },
                ],
                jidongyong: false,
            }
        },
        watch: {
            'ruleFormBalck.day': {
                handler() {
                    this.ruleFormBalck.xingshi = `由于您多次预约${this.tabIndexOne == 0 ? '活动' : '讲座'}后未能赴约，现对您禁止预约${this.tabIndexOne == 0 ? '活动' : '讲座'}${this.ruleFormBalck.day}天处罚。`
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.blackList();
            this.obtainCertificates();
        },
        methods: {
            //获取能选择的所有证件
            obtainCertificates() {
                this.$axios(this.api.registrationMethod.all, {}, 'get').then((res) => {
                    if (res.status) {
                        this.documentType = res.data;
                        this.documentType.unshift({id: '', cardTypeName: '全部'})
                    }
                })
            },

            blackList() {
                this.loading = true;
                this.$axios(this.api.activitymanagement.blackList, {
                    blackName: this.name,
                    blackState: this.selectState,
                    blackActivityType: this.tabIndexOne,
                    current: this.pageNo,
                    size: this.pageSize,
                    cardType: this.selectType,
                }, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total);
                        this.loading = false;
                    }
                })
            },

            //查询
            query() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.blackList()
            },

            // 重置
            resetSearch() {
                this.name = '',
                    this.selectState = '',
                    this.selectType = '',
                    this.blackList()
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.blackList()
            },

            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.blackList()
            },

            //释放
            addTo(row) {
                console.log(1)
                this.$confirm(`确定释放该账号吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.activity.releaseBlack + row.id, {}, 'get').then(res => {
                        if (res.status) {
                            this.$message.success('释放成功')
                            this.blackList()
                        } else {
                            this.$message.success('释放失败')
                        }
                    })
                });
            },

            //拉黑弹窗,num 1拉黑2加时长
            block(row, num) {
                this.laheioradd = num;
                this.id = row.id;
                this.ruleFormBalck.xingshi = `由于您多次预约${this.tabIndexOne == 0 ? '活动' : '讲座'}后未能赴约，现对您禁止预约${this.tabIndexOne == 0 ? '活动' : '讲座'}7天处罚。`
                this.dialogVisible = true;
            },

            //拉黑天数选择
            blackDaysSelection(num) {
                console.log(num)
                this.blackDay = num;
                if (num > 999) {
                    this.ruleFormBalck.xingshi = `由于您多次预约${this.tabIndexOne == 0 ? '活动' : '讲座'}后未能赴约，现对您禁止预约${this.tabIndexOne == 0 ? '活动' : '讲座'}永久的处罚。`
                } else {
                    this.ruleFormBalck.xingshi = `由于您多次预约${this.tabIndexOne == 0 ? '活动' : '讲座'}后未能赴约，现对您禁止预约${this.tabIndexOne == 0 ? '活动' : '讲座'}${num}天处罚。`
                }
            },

            //拉黑按钮
            lahei(formName) {
                if (this.ruleFormBalck.selectDay == '') { //自定义
                    this.blackDay = this.ruleFormBalck.day;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var obj = {
                            id: this.id,
                            blackDay: this.blackDay,
                            blackReason: this.ruleFormBalck.xingshi,
                        }
                        if (this.laheioradd == 1) {
                            this.$axios(this.api.activitymanagement.pullBlack, obj, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('拉黑成功')
                                } else {
                                    this.$message.error('拉黑失败')
                                }
                                this.resetForm('ruleFormBalck');
                                this.blackList();
                            })
                        } else {
                            this.$axios(this.api.activitymanagement.continueBlackTime, obj, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('加时长成功')
                                } else {
                                    this.$message.error('加时长失败')
                                }
                                this.resetForm('ruleFormBalck');
                                this.blackList();
                            })
                        }
                    }
                })
            },

            //发消息
            message(row) {
                this.id = row.id;
                this.dialogVisibleMessage = true;
            },

            //发消息按键
            gerenxiaoxi(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.activity.pullBlackMessage, {
                            id: this.id,
                            blackReason: this.ruleFormBalck.sendMessage,
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('消息发送成功')
                            } else {
                                this.$message.error('消息发送失败')
                            }
                            this.resetForm('ruleFormBalck');
                            this.dialogVisibleMessage = false;
                        })
                    }
                })
            },

            //详情
            details(row) {
                this.tabIndex = '0'
                this.userNamw = row.blackName;
                this.userphone = row.blackPhone;
                this.userIDcard = row.blackCard;
                if (this.tabIndex == 0) {
                    this.getByIdBlackSession();
                } else {
                    this.getByIdPullBlackDetail();
                }
                this.detailedData = true;
            },

            //未签到场次记录
            getByIdBlackSession() {
                this.detailedloading = true;
                this.$axios(this.api.activity.getByIdBlackSession, {
                    blackCard: this.userIDcard,
                    current: this.detailedpageNo,
                    size: this.detailedpageSize,
                    blackActivityType: this.tabIndexOne,
                }, 'post').then(res => {
                    if (res.status) {
                        this.detailedList = res.data.records;
                        this.detailedtotal = parseInt(res.data.total)
                    }
                    this.detailedloading = false;
                })
            },

            //详细拉黑记录
            getByIdPullBlackDetail() {
                this.detailedloading = true;
                this.$axios(this.api.activity.getByIdPullBlackDetail, {
                    blackCard: this.userIDcard,
                    current: this.detailedpageNo,
                    size: this.detailedpageSize,
                }, 'post').then(res => {
                    if (res.status) {
                        this.detailedList = res.data.records;
                        this.detailedtotal = parseInt(res.data.total)
                    }
                    this.detailedloading = false;
                })
            },

            //拉黑弹窗点击×关闭
            handleClose(done) {
                this.resetForm('ruleFormBalck');
            },

            //清空拉黑弹窗数据
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.blackDay = 7;
                this.dialogVisible = false;
                this.dialogVisibleMessage = false;
            },

            //拉黑tab切换
            handleSelectTab(index) {
                this.tabIndex = index;
                this.detailedpageNo = 1;
                this.detailedpageSize = 10;
                if (index == 0) {
                    this.getByIdBlackSession();
                } else {
                    this.getByIdPullBlackDetail();
                }
            },

            //详情关闭
            handleCloseData() {
                this.detailedData = false;
                // this.tabIndex = '0';
            },

            //详情每页数
            detailedSizeChangeHandle(val) {
                this.detailedpageSize = val;
                this.detailedpageNo = 1;
                if (this.tabIndex == 0) {
                    this.getByIdBlackSession();
                } else {
                    this.getByIdPullBlackDetail();
                }
            },

            //详情当前页
            detailedCurrentChangeHandle(val) {
                this.detailedpageNo = val
                if (this.tabIndex == 0) {
                    this.getByIdBlackSession();
                } else {
                    this.getByIdPullBlackDetail();
                }
            },

            //设置按钮点击
            setUp() {
                this.$axios(this.api.activity.list, {
                    size: "10", current: "1",
                }, 'get').then(res => {
                    if (res.status) {
                        this.laheiGuiz = res.data.records;
                        res.data.records.map(item => {
                            if (item.ruleType == 0) {
                                if (item.rule == 1) {
                                    this.warningSelect = item.ruleNumber;
                                } else if (item.rule == 2) {
                                    this.pullBlackSelsct = item.ruleNumber;
                                }
                            } else if (item.ruleType == 1) {
                                if (item.rule == 1) {
                                    this.warningSelect1 = item.ruleNumber;
                                } else if (item.rule == 2) {
                                    this.pullBlackSelsct1 = item.ruleNumber;
                                }
                            }
                        })
                        this.jidongyong = false;
                        this.setUpData = true;
                    }
                })
            },

            //黑名单设置弹窗关闭
            setUpDataHandleCloseData() {
                this.setUpData = false;
            },

            //黑名单设置确定
            setUpSure() {
                let shuju = [];
                this.laheiGuiz.map(item => {
                    if (item.ruleType == 0) {
                        if (item.rule == 1) {
                            item.ruleNumber = this.warningSelect;
                        } else if (item.rule == 2) {
                            item.ruleNumber = this.pullBlackSelsct;
                        }
                    } else if (item.ruleType == 1) {
                        if (item.rule == 1) {
                            item.ruleNumber = this.warningSelect1;
                        } else if (item.rule == 2) {
                            item.ruleNumber = this.pullBlackSelsct1;
                        }
                    }
                    shuju.push({id: item.id, ruleNumber: item.ruleNumber})
                })
                this.$axios(this.api.activity.batchRuleUpdateById, shuju, 'put').then(res => {
                    if (res.status) {
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                this.setUpDataHandleCloseData();
            },


            //黑名单设置取消
            setUpCancel() {
                this.setUpDataHandleCloseData();
            },

            //拉黑规则限制设置
            checkManagement() {
                let aa1 = '';
                let aa2 = '';
                if (this.warningSelect == this.pullBlackSelsct) {
                    if (this.warningSelect != 0) {
                        aa1 = true;
                        this.$message.warning('警告的未签到次数不能大于或等于拉黑的未签到次数')
                    } else {
                        aa1 = false;
                    }
                } else if (this.warningSelect < this.pullBlackSelsct) {
                    aa1 = false;
                } else if (this.warningSelect > this.pullBlackSelsct) {
                    if (this.pullBlackSelsct == 0) {
                        aa1 = false;
                    } else {
                        aa1 = true;
                        this.$message.warning('警告的未签到次数不能大于或等于拉黑的未签到次数')
                    }
                }

                if (this.warningSelect1 == this.pullBlackSelsct1) {
                    if (this.warningSelect1 != 0) {
                        aa2 = true;
                        this.$message.warning('警告的未签到次数不能大于或等于拉黑的未签到次数')
                    } else {
                        aa2 = false;
                    }
                } else if (this.warningSelect1 < this.pullBlackSelsct1) {
                    aa2 = false;
                } else if (this.warningSelect1 > this.pullBlackSelsct1) {
                    if (this.pullBlackSelsct1 != 0) {
                        aa2 = true;
                        this.$message.warning('警告的未签到次数不能大于或等于拉黑的未签到次数')
                    } else {
                        aa2 = false;
                    }
                }
                if (aa1 || aa2) {
                    this.jidongyong = true;
                } else {
                    this.jidongyong = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>
